import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/fonts/Tajawal-Black.ttf";
import "./assets/fonts/Tajawal-Bold.ttf";
import "./assets/fonts/Tajawal-Regular.ttf";
import "./assets/fonts/Tajawal-Medium.ttf";
import "./assets/fonts/Tajawal-ExtraBold.ttf";
import "./assets/fonts/Tajawal-Light.ttf";
import "./assets/fonts/Tajawal-ExtraLight.ttf";
import { Provider } from "react-redux";
import { store } from "./redux/store";



import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
if (process.env.REACT_APP_ENABLE_SENTRY === "1") {
  Sentry.init({
    dsn: "https://4888ecfc9daa4383bd720e77e7a130cc@o1142664.ingest.sentry.io/4505517193428992",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
