import { CLOSE_FAILURE_SNACKBAR, IFailureSnackBar, OPEN_FAILURE_SNACKBAR, UIAction } from "../actions/ui-actions.interface";
import { IUIState } from "../states/ui.state";

const intitialState: IUIState = {
    failureSnackBar: {
        failureSnackbarOpen: false,
        failureSnackbarMessage: ""
    }

};

export const uiReducer = (prevState:IUIState = intitialState, action: UIAction):IUIState => {
    switch (action.type) {
        case OPEN_FAILURE_SNACKBAR:
            let openFailureSnackBar: IFailureSnackBar = {
                failureSnackbarOpen: true,
                failureSnackbarMessage: action.message
            }
            return { ...prevState, failureSnackBar: openFailureSnackBar }
        case CLOSE_FAILURE_SNACKBAR:
            return { ...prevState, failureSnackBar: intitialState.failureSnackBar }
        default:
            return prevState
    }
};