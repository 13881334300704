import "./failure-dialog.scss";
import Dialog from "@mui/material/Dialog";
import close from "../../assets/logos/close.svg";
import DialogContent from "@mui/material/DialogContent";

export interface FailureDialogProps {
  open: boolean;
  failureMessage: string;
  onClose: () => void;
}

export function FailureDialog(props: FailureDialogProps) {
  const { onClose, failureMessage, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      scroll="body"
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "35px",
        },
      }}>
      <DialogContent>
        <div className="failure-container">
          <img alt="close" src={close} />
            <h2 className="failure-message">{failureMessage}</h2>
            <div className="close-btn--style">
              <button onClick={() => handleClose()}>برجاء المحاولة مرة أخري</button>
            </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
