import React from "react";
import { Resource } from "../../redux/actions/template-actions.interface";
import { store } from "../../redux/store";
import { ResourceModal } from "../resource-modal";
import mixpanel from "../../services/mixpanel";
import "./variant-data.css";
import { VariantPrice } from "../variant-price";
interface Props {
  totalPrice?: any;
  originalPrice?: any;
}
export const VariantData: React.FC<Props> = (props) => {
  const templateData = store.getState().template.activeTemplate;
  const [openResourceModal, setOpenResourceModal] = React.useState(false);
  const [resource, setResource] = React.useState("");
  function openModal(resourceSrc: string) {
    mixpanel.track("OC-V" + templateData.template_number + "-PhotoClick");
    setResource(resourceSrc);
    setOpenResourceModal(true);
  }
  function generateResource(resource: Resource, id) {
    if (resource.type === "image") {
      return (
        <img
          alt="product_image"
          className="small-resource"
          key={id}
          src={resource.src}
          onClick={() => openModal(resource.src)}
        />
      );
    } else {
      return <div key={id}></div>;
    }
  }
  const generateResources = () => {
    if (templateData.resources.length) {
      let mainResource = (
        <div className="main-resource" key={0}>
          <div className="m-auto">
            <img
              className="main-img"
              alt="variant_resource"
              src={templateData.resources[0].src}
            />
          </div>
        </div>
      );

      let resources: JSX.Element[] = [];
      for (let i = 0; i < 4; i++) {
        resources.push(<div key={i} className="empty-div"></div>);
      }
      let remainingVariantResources = templateData.resources.slice(1);
      for (let i = 0; i < remainingVariantResources.length; i++) {
        resources[i] = generateResource(remainingVariantResources[i], i);
      }

      return (
        <div>
          <div className="resource-container">
            {mainResource}
            <div className="resources-grid">{resources}</div>
          </div>
          <div className="product-description">
            <p
              style={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{
                __html: templateData.product_description,
              }}></p>
          </div>
        </div>
      );
    }
    return <div></div>;
  };
  return (
    <div className="variant">
      <ResourceModal
        open={openResourceModal}
        setOpen={setOpenResourceModal}
        resource={resource}></ResourceModal>
      <h3 className="centre">{templateData.product_name}</h3>
      <VariantPrice
        totalPrice={props?.totalPrice}
        originalPrice={props?.originalPrice}></VariantPrice>
      {generateResources()}
    </div>
  );
};
