import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

declare module "axios" {
  interface AxiosResponse<T = any> extends Promise<T> { }
}

export abstract class WebClient {
  private _baseURL = process.env.REACT_APP_CORE_URL || "https://us-central1-dev-alkaseba-d3f6c.cloudfunctions.net";
  protected instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: this._baseURL,
    });
    this.instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    this._initializeResponseInterceptor();
    this._initializeRequestInterceptor();
  }

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError
    );
  };

  private _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this._addAuthQueryParam);
  };

  private _addAuthQueryParam = (request: AxiosRequestConfig) => {

    let token = this.getToken()
    let headers = request.headers || {}
    headers["Authorization"] = token.toString()
    request.headers = headers
    return request;
  };
  private getToken(): string {
    const token = localStorage.getItem("token")
    return "Bearer " + token;
  }

  private _handleResponse = ({ data }: AxiosResponse) => data;

  private _handleError = (error: AxiosError) => {
    throw error
  };
}
