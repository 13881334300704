import * as React from "react";
import { FirstTemplate } from "./first-template";
import { ForthTemplate } from "./forth-template";
import { SecondTemplate } from "./second-template";
import { ThirdTemplate } from "./third-template";
import inActive from "../assets/logos/inActive.svg";
import "./main.scss";

interface Props {
  templateNumber: number;
  order_code: string;
  payment_id: string;
  failure_message: string;
  token_exist: boolean;
}

export const Main: React.FC<Props> = (props) => {
  const returnTemplate = () => {
    switch (props.templateNumber) {
      case 1:
        return <FirstTemplate />;
      case 2:
        return <SecondTemplate />;
      case 3:
        return <ThirdTemplate />;
      case 4:
        return (
          <ForthTemplate
            order_code={props.order_code}
            payment_id={props.payment_id}
            failure_message={props.failure_message}
          />
        );
      default:
        return <div></div>
    }
  };

  if (props.token_exist === true && (props.templateNumber <=4 && props.templateNumber !== 0)) {
    return <>{returnTemplate()}</>;
  } else if (props.token_exist === false &&( props.templateNumber  === 0 || props.templateNumber >4)) {
    return (
      <div className="empty-div">
        <img className="in-active-image" alt="inActive" src={inActive} />
        <h2 className="in-active">الصفحة غير موجوده</h2>
      </div>
    );
  }
  else{
    return <div></div>
  }
};
