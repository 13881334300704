import { GetAreasResponse, GetCitiesResponse } from "../../../core/models/location.model";
import { ILocationRepository } from "../../../core/repositories/location-repository.interface";
import { WebClient } from "../web-client";

export class LocationRepository
  extends WebClient
  implements ILocationRepository
{
    private static _instance: LocationRepository;
    private constructor() {
        super();
        
      }
    
      public static getInstance() {
        if (!this._instance) {
          this._instance = new LocationRepository();
        }
        return this._instance;
      }
   
    
    async getAreas(city_id: string): Promise<GetAreasResponse> {
        const response = this.instance.get(`app/area/${city_id}`)
        return Promise.resolve(response);
    }
    async getCities(): Promise<GetCitiesResponse> {
        const response = this.instance.get('app/city')
        return Promise.resolve(response);
        
    }
}