import React, { useState } from "react";
import { FormFieldModel } from "../../helpers/form-field.model";
import GoogleMapComponent from "../../components/Map/GoogleMapsComponent";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import classNames from "classnames";
import "./form.scss";
import { FormHelper } from "../../helpers/form-helper";
import mixpanel from "../../services/mixpanel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { OrderRepository } from "../../services/data/web/repositories/order.repository";
import { useDispatch } from "react-redux";
import { openFailureSnackBar } from "../../redux/actions/ui-actions";
import { store } from "../../redux/store";
import { AvailablePayment } from "../../services/core/models/order.model";
import wallet from "../../assets/logos/wallet.svg";
import visa from "../../assets/logos/visa.png";
import master from "../../assets/logos/card_mastercard.svg";
import mada from "../../assets/logos/mada_logo.png";
import lock from "../../assets/logos/lock.svg";
import Discount_Tag from "../../assets/logos/Discount_Tag.svg";
import Debit_Card from "../../assets/logos/Debit_Card.svg";
interface Props {
  title?: any;
  formFieldArray: FormFieldModel[];
  form: any;
  formSetter: any;
  errors: any;
  errorsSetter: any;
  onChange: any;
  template: string;
  marketPlace: string;
  quantity?: number;
  paymentItems?: AvailablePayment[];
  prepaidDiscountQuantity?: number;
}

export const Form = React.forwardRef((props: Props, ref) => {
  let title = props.title;
  const orderRepository = OrderRepository.getInstance();
  const dispatch = useDispatch();
  const shipping_price = store.getState().template.activeTemplate.shipping_price
    ? store.getState().template.activeTemplate.shipping_price
    : 30;
  const [items, setItems] = useState<AvailablePayment[]>([]);
  const [radioValue, setRadioValue] = useState("cod");
  const [isWaitingPaymentLoading, setIsWaitingPaymentLoading] =
    React.useState(false);
  if (props.paymentItems) {
    setItems(props.paymentItems);
  }

  function isEmptyDict(dict: any): boolean {
    return Object.keys(dict).length === 0;
  }

  const handlePositionChange = (newPosition) => {
    props.formSetter({ ...props.form, "google-maps": newPosition });
  };

  function handleChange(key, event) {
    let value = event.target.value;
    let fieldErrors = {};
    props.formSetter({ ...props.form, [key]: value });
    for (let formField of props.formFieldArray) {
      if (key === formField.key) {
        let validationResult = FormHelper.validateFormField(
          value,
          formField.validationFuncArray
        );
        formField.isValid = validationResult[0];
        formField.errors = validationResult[1];
        fieldErrors = formField.errors;
        if (key === "payment_method") {
          mixpanel.track(`OC - ${event.target.value} Click`);
          setRadioValue(value);
          props.formSetter({ ...props.form, [key]: value });
          let validationResult = FormHelper.validateFormField(
            value,
            formField.validationFuncArray
          );
          formField.isValid = validationResult[0];
          formField.errors = validationResult[1];

          if (value !== "cod") {
            let paymentItem = items.find((paymentItem) => {
              return paymentItem.payment_method === value;
            });
            if (paymentItem) {
              paymentItem.select_payment = true;
            }
          }
        }
      }
      // if (key === "customer_phone_number") {
      //   setItems([]);
      //   for (let eachFormField of props.formFieldArray) {
      //     if (eachFormField.key === "payment_method") {
      //       props.formSetter({
      //         ...props.form,
      //         [eachFormField.key]: "paytabs",
      //         [key]: value,
      //       });
      //       let validationResult = FormHelper.validateFormField(
      //         value,
      //         formField.validationFuncArray
      //       );
      //       formField.isValid = validationResult[0];
      //       formField.errors = validationResult[1];
      //       setRadioValue("paytabs");
      //     }
      //   }
      // }
    }
    props.onChange(key, value);
    props.errorsSetter({ ...props.errors, [key]: fieldErrors });
  }

  function handleOnFocus(key) {
    mixpanel.track("OC-V" + props.template + "-" + key);
  }

  function handleAutocompleteChange(key, newValue) {
    if (newValue === null) {
      return;
    }
    let value = newValue.id;
    let fieldErrors = {};
    props.formSetter({ ...props.form, [key]: value });
    for (let formField of props.formFieldArray) {
      if (key === formField.key) {
        let validationResult = FormHelper.validateFormField(
          value,
          formField.validationFuncArray
        );
        formField.isValid = validationResult[0];
        formField.errors = validationResult[1];
        fieldErrors = formField.errors;
      }
    }
    props.onChange(key, value);
    props.errorsSetter({ ...props.errors, [key]: fieldErrors });
  }

  const getAvailablePayment = () => {
    setIsWaitingPaymentLoading(true);
    mixpanel.track("OC-V4-get_available_payment");

    setItems([]);
    let createOrderPayload = FormHelper.getOrderCreationPayload(
      props.form,
      props.quantity,
      shipping_price,
      props.prepaidDiscountQuantity
    );
    orderRepository
      .getAvailablePayment(createOrderPayload)
      .then((data) => {
        if (data.payment_response.length > 0) {
          const containsPaytabs = data.payment_response.some(
            (payment_method) => payment_method.payment_method === "paytabs"
          );
          if (containsPaytabs) {
            setRadioValue("paytabs");
            const paymentKey = "payment_method";
            props.formSetter({ ...props.form, [paymentKey]: "paytabs" });
          } else {
            setRadioValue("cod");
          }

          if (radioValue !== "cod") {
            let paymentItem = data.payment_response.find((paymentItem) => {
              return paymentItem.payment_method === radioValue;
            });
            if (paymentItem) {
              paymentItem.select_payment = true;
            }
          }
          setItems(data.payment_response);
        } else {
          dispatch(openFailureSnackBar("نأسف لا يوجد طرق دفع أخري متاحة الان"));
        }
        setIsWaitingPaymentLoading(false);
      })
      .catch((error) => {
        setIsWaitingPaymentLoading(false);
        dispatch(openFailureSnackBar(error?.response?.data?.message));
      });
  };

  // const requestGetAvailablePayment = () => {
  //   let isValid = FormHelper.validateFormWithoutPayment(
  //     props.formFieldArray,
  //     props.form,
  //     props.errors,
  //     props.errorsSetter
  //   );
  //   if (isValid) {
  //     getAvailablePayment();
  //   }
  // };

  React.useImperativeHandle(ref, () => ({
    getAvailablePayment,
  }));

  function disablePaymentBesedonError(error): boolean {
    if (error) {
      return true;
    }
    return false;
  }

  const generateFormFields = () => {
    let formFields = props.formFieldArray.map(function (formField, id) {
      if (formField.type === "drop-down") {
        return (
          <FormControl
            fullWidth
            key={id}
            size="small"
            className={classNames({
              error: !isEmptyDict(props.errors[formField.key]),
              mb: true,
            })}>
            <label>{formField.label}</label>
            <Select
              size="small"
              fullWidth
              className="border-style dropdown-style"
              value={props.form[formField.key]}
              defaultValue={props.form[formField.key]}
              onChange={(e) => handleChange(formField.key, e)}
              onFocus={(e) => handleOnFocus(formField.key)}>
              <MenuItem value={-1} style={{ display: "none" }}></MenuItem>
              {formField.dropdown?.optionArray.map((option, idx) => (
                <MenuItem
                  key={option[formField.dropdown?.idKey || ""]}
                  value={option[formField.dropdown?.idKey || ""]}>
                  {option[formField.dropdown?.valueKey || ""]}
                </MenuItem>
              ))}
            </Select>
            <div className="errormsg-container">
              <small
                className={classNames({
                  errormsg: "required" in props.errors[formField.key],
                  none: !("required" in props.errors[formField.key]),
                })}>
                {" "}
                برجاء ملئ هذه الخانة
              </small>
            </div>
          </FormControl>
        );
      } else if (formField.type === "autocomplete-drop-down") {
        return (
          <FormControl
            fullWidth
            key={id}
            size="small"
            className={classNames({
              error: !isEmptyDict(props.errors[formField.key]),
              mb: true,
            })}>
            <label>{formField.label}</label>
            <Autocomplete
              disablePortal
              size="small"
              fullWidth
              className="border-style dropdown-style"
              onChange={(e, newValue) =>
                handleAutocompleteChange(formField.key, newValue)
              }
              onFocus={(e) => handleOnFocus(formField.key)}
              renderInput={(params) => <TextField {...params} />}
              options={formField.dropdown?.optionArray}
              //@ts-ignore
              getOptionLabel={(option) => option[formField.dropdown?.valueKey]}
            />
            <div className="errormsg-container">
              <small
                className={classNames({
                  errormsg: "required" in props.errors[formField.key],
                  none: !("required" in props.errors[formField.key]),
                })}>
                {" "}
                برجاء ملئ هذه الخانة
              </small>
            </div>
          </FormControl>
        );
      } else if (formField.type === "google-maps") {
        return (
          <div key={id} className="center mb">
            <label>{formField.label} </label>
            <GoogleMapComponent onPositionChange={handlePositionChange} />
          </div>
        );
      } else if (formField.type === "text") {
        return (
          <div
            key={id}
            className={classNames({
              error: !isEmptyDict(props.errors[formField.key]),
              mb: true,
            })}>
            <label>{formField.label}</label>
            <TextField
              value={props.form[formField.key]}
              fullWidth
              hiddenLabel
              variant="filled"
              size="small"
              placeholder={formField.placeholder}
              InputProps={{ disableUnderline: true }}
              className="input-field-styles border-style"
              inputProps={{
                maxLength: formField?.maxLength ? formField?.maxLength : 1000,
              }}
              onChange={(e) => handleChange(formField.key, e)}
              onFocus={(e) => handleOnFocus(formField.key)}
            />
            <div className="errormsg-container">
              <small
                className={classNames({
                  errormsg: "required" in props.errors[formField.key],
                  none: !("required" in props.errors[formField.key]),
                })}>
                {" "}
                برجاء ملئ هذه الخانة
              </small>
              <small
                className={classNames({
                  errormsg: "phonePattern" in props.errors[formField.key],
                  none:
                    !("phonePattern" in props.errors[formField.key]) ||
                    "required" in props.errors[formField.key],
                })}>
                {" "}
                برجاء ادخال رقم الهاتف صحيحًا
              </small>
            </div>
          </div>
        );
      } else if (
        formField.type === "radio" &&
        formField.specialType === "payment" &&
        props.marketPlace === "Saudi Arabia"
      ) {
        const formControlLabelStyle = {
          "& .MuiFormControlLabel-label": {
            fontSize: "1rem",
            fontFamily: "Tajwal",
          },
        };
        return (
          <div>
            <div className="payment-section">
              <div className="payment-available"> طرق الدفع المتاحة *</div>
            </div>

            {props.prepaidDiscountQuantity !== undefined &&
              props.prepaidDiscountQuantity !== 0 && (
                <div className="discount-section">
                  <div className="discount-background">
                    <div className="discount-content">
                      <div>
                        <img src={Discount_Tag} alt="Discount_Tag" />
                      </div>
                      <div>
                        هتستمتع بخصم
                        {" " + props.prepaidDiscountQuantity + " "}
                        ريال علي الأوردر لو استخدمت احد طرق الدفع الالكتروني
                      </div>
                      <div>
                        <img src={Debit_Card} alt="Debit_Card" />
                      </div>
                    </div>
                  </div>
                </div>
              )}

            <FormControl
              key={id}
              className={classNames({
                error: !isEmptyDict(props.errors[formField.key]),
                classNames: "radio-container",
                "radio-container": true,
              })}>
              <RadioGroup
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={props.form[formField.key]}
                onChange={(e) => handleChange(formField.key, e)}
                className="radio-group--style">
                {items.length > 0 &&
                  items.map((item) => (
                    <div key={item.payment_method}>
                      {item.payment_method === "paytabs" ? (
                        <div
                          className={`payment-style border rounded-lg divide-black p-1 ${
                            "required" in props.errors[formField.key]
                              ? "flag-error-input"
                              : ""
                          }`}>
                          <FormControlLabel
                            value={item.payment_method}
                            control={
                              <Radio
                                checked={radioValue === item.payment_method}
                              />
                            }
                            label={
                              <div className="outer-paytabs--container">
                                <div className="paytabs-style">
                                  <div className="available-paytabs--support">
                                    <img src={master} alt="master" />
                                    <img src={mada} alt="mada" />
                                    <img src={visa} alt="visa " />
                                  </div>
                                  <div className="paytabs-powered-by">
                                    <img
                                      src={item.payment_logo}
                                      alt={item.payment_method}
                                    />
                                    <div className="powered-by--style">
                                      Powered By
                                    </div>
                                    <div className="paytabs-lock--support">
                                      <img src={lock} alt="lock" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                            sx={{ ...formControlLabelStyle }}
                          />
                        </div>
                      ) : (
                        <div
                          className={`payment-style border rounded-lg divide-black p-1 ${
                            "required" in props.errors[formField.key]
                              ? "flag-error-input"
                              : ""
                          }`}>
                          <div className="payment--gateway--style">
                            <FormControlLabel
                              key={item.payment_method}
                              value={item.payment_method}
                              control={
                                <Radio
                                  disabled={disablePaymentBesedonError(
                                    item.error
                                  )}
                                  checked={radioValue === item.payment_method}
                                />
                              }
                              label={
                                <div className="payment-label">
                                  <img
                                    src={item.payment_logo}
                                    alt={item.payment_method}
                                  />
                                  <div className="payment-msg installment-msg">
                                    {item.installment_msg}
                                  </div>
                                </div>
                              }
                            />
                          </div>

                          {item.select_payment && (
                            <div className="installment">
                              <div className="payment-container">
                                {item.payment_installment &&
                                  item.payment_installment.map(
                                    (installment) => (
                                      <div className="payment-card">
                                        <div className="payment-header">
                                          <h2>{installment.due_date}</h2>
                                        </div>
                                        <div className="payment-body">
                                          <p className="payment-amount">
                                            {installment.amount}
                                          </p>
                                        </div>
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                          )}

                          <div className="payment-error--style">
                            <div className="payment-error--message">
                              {item.error}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

                <div
                  className={`payment-style border rounded-lg divide-black p-1 ${
                    "required" in props.errors[formField.key]
                      ? "flag-error-input"
                      : ""
                  }`}>
                  <FormControlLabel
                    value="cod"
                    control={<Radio checked={radioValue === "cod"} />}
                    label={
                      <div className="cash-on-delivery">
                        <img src={wallet} alt="cash" />
                        <div className="payment-msg">
                          الدفع نقدا عند الاستلام
                        </div>
                      </div>
                    }
                    sx={{ ...formControlLabelStyle }}
                  />
                </div>
              </RadioGroup>

              <div className="errormsg-container">
                <small
                  className={classNames({
                    errormsg: "required" in props.errors[formField.key],
                    none: !("required" in props.errors[formField.key]),
                  })}>
                  {" "}
                  برجاء ملئ هذه الخانة
                </small>
                <small
                  className={classNames({
                    errormsg: "paymentMethod" in props.errors[formField.key],
                    none:
                      !("paymentMethod" in props.errors[formField.key]) ||
                      "required" in props.errors[formField.key],
                  })}>
                  عفوا, يجب اختيار الدفع عند الاستلام او تابي او تامارا
                </small>
              </div>
            </FormControl>
          </div>
        );
      } else return <div key={id}></div>;
    });

    return <div>{formFields}</div>;
  };

  return (
    <div>
      {isWaitingPaymentLoading && (
        <div className="loading--overlay">
          <div className="spinner">
            <CircularProgress />
            <div className="waiting-message">
              برجاء الانتظار للتحقق من طرق الدفع المتاحة
            </div>
          </div>
        </div>
      )}
      {/* <h2 id="title">
        <ins>{title}</ins>
      </h2> */}
      <div id="title" className="title">
        <div className="title-style">{title}</div>
      </div>
      {generateFormFields()}
    </div>
  );
});
