import { IValidator } from "./validator-interface";

export class PaymentMethodValidator implements IValidator {
  public validate(value: string): [boolean, any] {
    let availablePaymentMethod = ["cod", "tabby", "tamara", "paytabs"];
    if (value) {
      if (!availablePaymentMethod.includes(value)) {
        return [false, { paymentMethod: true }];
      } else {
        return [true, {}];
      }
    }
    return [true, {}];
  }
}
