import {
  egypt_pattern_arbic,
  egypt_pattern_eng,
  egypt_phone_ex,
  saudi_pattern_eng,
  saudi_phone_ex,
  uae_pattern_eng,
  uae_phone_ex,
  convertToEnglishNumber,
} from "./phone-regex";
import { IValidator } from "./validator-interface";

export class PhoneNumberValidator implements IValidator {
  private marketplace: string;
  private regexExp: RegExp;

  constructor(marketplace: string) {
    this.marketplace = marketplace;
    if (this.marketplace.toLowerCase() === "egypt") {
      this.regexExp = new RegExp(egypt_pattern_eng + "|" + egypt_pattern_arbic);
    } else if (this.marketplace.toLowerCase() === "emirates") {
      this.regexExp = new RegExp(uae_pattern_eng);
    } else {
      this.regexExp = new RegExp(saudi_pattern_eng);
    }
  }
  public validate(value: string): [boolean, any] {
    if (value) {
      let phoneValue = convertToEnglishNumber(value);
      let regexMatch: boolean = this.regexExp.test(phoneValue);
      if (regexMatch) {
        return [true, {}];
      } else {
        return [false, { phonePattern: true }];
      }
    }
    return [true, {}];
  }
}

export function phoneNumberPlaceholder(marketplace: string): string {
  if (marketplace.toLowerCase() === "egypt") {
    return egypt_phone_ex;
  } else if (marketplace.toLowerCase() === "emirates") {
    return uae_phone_ex;
  } else {
    return saudi_phone_ex;
  }
}
