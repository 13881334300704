import React from "react";
import flextock_logo from "../../assets/logos/flextock_logo.svg";
import "./header.css";

export const Header: React.FC = () => {
  return (
    <div className="header-container">
      <img alt="flextock_logo" src={flextock_logo} />
    </div>
  );
};
