import React from "react";
import "./template-title.css";

export const TemplateTitle: React.FC = () => {
  return (
    <div className="success">
      <h2 style={{ textAlign: "center" }} >
        قم باكمال عملية الشراء
      </h2>
    </div>
  );
};
