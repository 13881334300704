import React, { useLayoutEffect } from "react";
import { store } from "../../redux/store";
import "./variant-price.scss";
interface Props {
  totalPrice?: any;
  originalPrice?: any;
}
export const VariantPrice: React.FC<Props> = (props) => {
  const [currency, getCurrencyFromState] = React.useState("");

  useLayoutEffect(() => {
    getCurrencyFromState(store.getState().template.activeTemplate.marketplace.currency);
  }, []);
  let originalPrice = props?.originalPrice ? props?.originalPrice : 0;

  return (
    <div className="price-container"> 
      <p className="text">السعر لفتره محدودة</p>
      <div className="price">
        <p key={props?.totalPrice}>
        {props?.totalPrice} {currency === "SAR"?"ريال": currency === "AED"?"درهم اماراتي" : "جنيه"} 
        </p>
        <div  key={props?.originalPrice}><h3 className="instead_of"><div className="instead_of_text">بدلا من</div>  <del>{originalPrice}  {currency === "SAR"?"ريال":"جنيه"} 
</del></h3></div>
      </div>
    </div>
  );
};
