import React, { useEffect } from "react";
import smile from "../../assets/logos/smile.svg";
import {
  CLEAR_STATE,
  IClearStateAction,
} from "../../redux/actions/app-actions.interface";
import { store } from "../../redux/store";
import mixpanel from "../../services/mixpanel";
import "./successful-creation.css";
export const SuccessfulCreation: React.FC = () => {
  mixpanel.track("OC-V4-Success-Page");
  useEffect(() => {
    let action: IClearStateAction = {
      type: CLEAR_STATE,
    };
    store.dispatch(action);
  }, []);
  return (
    <div className="success-container">
      <img alt="smile" src={smile} />
      <div>
        <h2 className="success">شكرا لتسجيل طلبك</h2>
        <h2 className="success">سنقوم بالتواصل معكم</h2>
      </div>
    </div>
  );
};
