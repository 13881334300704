export const INITIALIZE_TEMPLATE = "INITIALIZE TEMPLATE";

export type Resource = {
  type: string;
  src: string;
};

export type Marketplace = {
  id: number;
  name: string;
  currency: string;
};
export interface ITemplateInterface {
  template_number: number;
  seller_id: number;
  product_name: string;
  product_description: string;
  selling_price: number;
  price_before_offer: number;
  resources: Resource[];
  marketplace: Marketplace;
  google_tag_manager: string;
  facebook_pixel: string;
  tiktok_pixel: string;
  shipping_price: number;
  logoLink: Resource;
  variant_sku_code: string;
  is_active: boolean;
  token_key: string;
}

export type TemplateState = {
  activeTemplate: ITemplateInterface;
};
export interface ITemplateAction {
  type: typeof INITIALIZE_TEMPLATE;
  templateData: ITemplateInterface;
}

export type TemplateAction = ITemplateAction;
