
import { GetTokenResponse } from "../../../core/models/token.model";
import { WebClient } from "../web-client";


export class TokenRepository
  extends WebClient
  {
    private static _instance: TokenRepository;
    private constructor() {
        super();
        
      }
    
      public static getInstance() {
        if (!this._instance) {
          this._instance = new TokenRepository();
        }
        return this._instance;
      }
      async getoken(code: string): Promise<GetTokenResponse> {
        const response = this.instance.get(`app/token/${code}`)
        return Promise.resolve(response);
    }
  }