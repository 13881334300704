export const OPEN_FAILURE_SNACKBAR = "OPEN_FAILURE_SNACKBAR"
export const CLOSE_FAILURE_SNACKBAR = "CLOSE_FAILURE_SNACKBAR"

export interface IFailureSnackBar {
    failureSnackbarOpen: boolean,
    failureSnackbarMessage: string
}

export interface IOpenFailureSnackBarAction {
    type: typeof OPEN_FAILURE_SNACKBAR,
    message: string
}
export interface ICloseFailureSnackBarAction {
    type: typeof CLOSE_FAILURE_SNACKBAR
}



export type UIAction = IOpenFailureSnackBarAction | ICloseFailureSnackBarAction