import {
  INITIALIZE_TEMPLATE,
  TemplateAction,
} from "../actions/template-actions.interface";
import { ITemplateState } from "../states/template.state";

const intitialState: ITemplateState = {
  activeTemplate: {
    template_number: 0,
    seller_id: 0,
    product_name: "",
    product_description: "",
    selling_price: 0,
    price_before_offer: 0,
    resources: [],
    google_tag_manager: "",
    facebook_pixel: "",
    tiktok_pixel: "",
    marketplace: { id: 0, name: "", currency: "" },
    shipping_price: 0,
    logoLink: { src: "", type: "" },
    variant_sku_code: "",
    is_active: true,
    token_key: "",
  },
};

export const templateReducer = (
  prevState = intitialState,
  action: TemplateAction
) => {
  switch (action.type) {
    case INITIALIZE_TEMPLATE:
      return { ...prevState, activeTemplate: action.templateData };
  }
  return prevState;
};
