import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import * as React from "react";
import { Header } from "./components/header";
import { SuccessfulCreation } from "./components/successful-creation";
import { initializaTemplateAction } from "./redux/actions/template.action";
import { useDispatch } from "react-redux";
import { useLayoutEffect } from "react";
import { store } from "./redux/store";
import { Main } from "./pages";
import { FailureSnackBar } from "./components/failure-snackbar";
import {
  getCodeFromQueryParams,
  getFailureMessageFromQueryParams,
  getOrderCodeFromQueryParams,
  getPaymentIDFromQueryParams,
  getTokenKeyFromQueryParams,
} from "./helpers/query-params-helper";
import { CustomerBill } from "./components/customer-bill";
import * as Sentry from "@sentry/react";
import TagManager from "react-gtm-module";
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';
import { TokenRepository } from "./services/data/web/repositories/token.repository";
import { initializeApp } from "firebase/app";
import { doc, getDoc, getFirestore } from "firebase/firestore";

function App() {
  const token_key = getTokenKeyFromQueryParams();
  const code = getCodeFromQueryParams();
  const order_code = getOrderCodeFromQueryParams();
  const payment_id = getPaymentIDFromQueryParams();
  const failure_message = getFailureMessageFromQueryParams();
  const tokenRepo = TokenRepository.getInstance();
  const [templateNumberState, setTemplateNumberState] = React.useState(0);
  const [token, setToken] = React.useState("");
  const [tokenExist, setTokenExist] = React.useState(true);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const get_token_from_key = async function (token_key) {
      const firebaseConfig = JSON.parse(
        process.env.REACT_APP_FIREBASE_CONFIG || ""
      );
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);

      const docRef = doc(db, "Sellers Tokens", token_key);
      const docSnap = await getDoc(docRef);
      setTokenExist(docSnap.exists());

      if (docSnap.exists()) {
        setToken(docSnap.data().token);
        localStorage.setItem("token", docSnap.data().token);
        dispatch(initializaTemplateAction(token));
        setTemplateNumberState(
          store.getState().template.activeTemplate.template_number
        );
        const google_tag_id =
          store.getState().template.activeTemplate.google_tag_manager;
        if (google_tag_id) {
          const tagManagerArgs = {
            gtmId: google_tag_id,
          };
          TagManager.initialize(tagManagerArgs);
        }
        const facebook_pixel_id =
          store.getState().template.activeTemplate.facebook_pixel;
        if (facebook_pixel_id) {
          ReactPixel.init(facebook_pixel_id);
          ReactPixel.pageView();
        }
        const tiktok_pixel_id = store.getState().template.activeTemplate.tiktok_pixel;
        if(tiktok_pixel_id){
          TiktokPixel.init(tiktok_pixel_id);
          TiktokPixel.pageView()
        }
      }
      return;
    };
    if (token_key !== "" && token_key !== undefined) {
      get_token_from_key(token_key);
    } else if (code !== "") {
      tokenRepo.getoken(code).then((data) => {
        localStorage.setItem("token", data.token);
        dispatch(initializaTemplateAction(data.token));
        setTemplateNumberState(
          store.getState().template.activeTemplate.template_number
        );
      });
    }
    Sentry.setContext("meta", {
      tokenData: store.getState().template.activeTemplate,
      marketplaceData: store.getState().template.activeTemplate.marketplace,
    });
  }, [dispatch, token, code, tokenRepo, token_key]);
  return (
    <section>
      <Header />
      <Router>
        <FailureSnackBar />
        <div className="main-container">
          <Routes>
            <Route
              path="/"
              element={
                <Main
                  key={templateNumberState}
                  templateNumber={templateNumberState}
                  order_code={order_code}
                  payment_id={payment_id}
                  failure_message={failure_message}
                  token_exist = {tokenExist} 
                />
              }></Route>
            <Route path="/success" element={<SuccessfulCreation />}>
              {" "}
            </Route>

            <Route path="/customer-bill" element={<CustomerBill />}>
              {" "}
            </Route>
          </Routes>
        </div>
      </Router>
    </section>
  );
}

export default App;
