import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./resource-modal.css";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "transparent",
  border: "none",
  boxShadow: 0,
  p: 0,
};
interface Props {
  setOpen: any;
  open: boolean;
  resource: string;
}
export const ResourceModal: React.FC<Props> = (props) => {
  const handleClose = () => props.setOpen(false);
  return (
    <div>
      <Modal open={props.open} onClose={handleClose}>
        <Box className="modal-container" sx={style}>
          <button className="close-btn" onClick={() => handleClose()}>
            <span className="material-symbols-outlined">close</span>
          </button>
          <img alt="img-modal" className="img-style" src={props.resource} />
        </Box>
      </Modal>
    </div>
  );
};
