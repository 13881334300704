export const egypt_pattern_eng: string = "^((\\+2|002)?01[0125][0-9]{8})$";
export const egypt_pattern_arbic: string =
  "^((\\+\\u0662|\\u0660\\u0660\\u0662)?\\u0660\\u0661[\\u0660\\u0661\\u0662\\u0665][\\u0660-\\u0669]{8})$";
export const saudi_pattern_eng: string =
  "^(009665|9665|\\+9665|05|5)(5|0|3|6|4|9|1|8|7)[0-9]{7}$";
export const uae_pattern_eng: string = "^((\\+|00)(971)|0)?[1-9][0-9]{8}$";
export const saudi_phone_ex: string = "مثال: 05xxxxxxxx ";
export const egypt_phone_ex: string =
  "مثال: 010xxxxxxxx, 011xxxxxxxx, 012xxxxxxxx, 015xxxxxxxx ";
export const uae_phone_ex: string = "مثال: +9715XXXXXXXX";

// Arrays for Arabic and English numbers
const arabicNumbers: string[] = [
  "٠",
  "١",
  "٢",
  "٣",
  "٤",
  "٥",
  "٦",
  "٧",
  "٨",
  "٩",
];
const englishNumbers: string[] = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

// Function to check if a string contains Arabic numbers
function _isArabicNumber(input: string): boolean {
  return arabicNumbers.some((arabicDigit) => input.includes(arabicDigit));
}

export function convertToEnglishNumber(PhoneNumber: string): string {
  if (PhoneNumber) {
    // Check if the input is an Arabic number
    if (_isArabicNumber(PhoneNumber)) {
      // Convert Arabic number to English
      let englishNumber = "";
      for (let i = 0; i < PhoneNumber.length; i++) {
        const index = arabicNumbers.indexOf(PhoneNumber[i]);
        if (index !== -1) {
          //In case the number is already in English
          englishNumber += englishNumbers[index];
        } else {
          // Handle non-Arabic digit (e.g., decimal point)
          englishNumber += PhoneNumber[i];
        }
      }
      console.log("English equivalent:", englishNumber);
      return englishNumber;
    } else {
      // Print the input as is if it's already an English number
      console.log("Input is already an English number:", PhoneNumber);
      return PhoneNumber;
    }
  } else {
    return "";
  }
}
