import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "../../components/form";
import { SubmitButton } from "../../components/submit-button";
import { TemplateTitle } from "../../components/template-title";
import { VariantData } from "../../components/variant-data";
import { VariantQuantityIncrement } from "../../components/variant-quantity-increment";
import { FormFieldModel } from "../../helpers/form-field.model";
import { FormHelper } from "../../helpers/form-helper";
import { Area, City } from "../../services/core/models/location.model";
import { LocationRepository } from "../../services/data/web/repositories/location.repository";
import { DropDownRequiredValidator } from "../../validators/dropdown-required-validator";
import { PhoneNumberValidator } from "../../validators/phone-number-validator";
import { RequiredValidator } from "../../validators/required-validator";
import mixpanel from "../../services/mixpanel";
import { useDispatch } from "react-redux";
import { addOrder } from "../../redux/actions/order.actions";
import { OrderData } from "../../redux/actions/order-actions.interface";
import { store } from "../../redux/store";

interface Props {
  order_code?: string;
}
export const ThirdTemplate: React.FC<Props> = (props) => {
  const [cities, setCities] = React.useState<City[]>([]);
  const [areas, setAreas] = React.useState<Area[]>([]);
  const [quantity, setQuantity] = React.useState(1);
  const [totalPrice, setTotalPrice] = React.useState(
    store.getState().template.activeTemplate.selling_price
  );
  const locationRepo = LocationRepository.getInstance();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    mixpanel.time_event("OC-V3-page-entry");
    mixpanel.track("OC-V3-page-entry");
    // window.addEventListener("scroll", handleOnScroll);
    setCitiesState(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleOnScroll = () => {
  //   mixpanel.track("OC-V3-scroll");
  // };

  let formFieldArray: FormFieldModel[] = [
    {
      key: "customer_name",
      label: "الأسم*",
      isValid: false,
      validationFuncArray: [new RequiredValidator()],
      type: "text",
      maxLength: 100,
      initialValue: "",
      errors: {},
    },
    {
      key: "customer_phone_number",
      label: "رقم الموبايل 1 *",
      isValid: false,
      validationFuncArray: [
        new RequiredValidator(),
        new PhoneNumberValidator(
          store.getState().template.activeTemplate.marketplace.name
        ),
      ],
      type: "text",
      initialValue: "",
      errors: {},
    },
    {
      key: "customer_secondary_phone_number",
      label: "رقم الموبايل2",
      isValid: true,
      validationFuncArray: [
        new PhoneNumberValidator(
          store.getState().template.activeTemplate.marketplace.name
        ),
      ],
      initialValue: "",
      type: "text",
      errors: {},
    },
    {
      key: "google-maps",
      label: "قم باختيار موقعك من الخريطة ( ينصح به)",
      isValid: true,
      validationFuncArray: [],
      initialValue: {},
      type: "google-maps",
      errors: {},
    },

    {
      key: "street_name",
      label: "اسم ورقم الشارع* ",
      isValid: false,
      validationFuncArray: [new RequiredValidator()],
      initialValue: "",
      maxLength: 100,
      type: "text",
      errors: {},
    },
    {
      key: "building_number",
      label: "رقم المبني*",
      isValid: false,
      validationFuncArray: [new RequiredValidator()],
      initialValue: "",
      maxLength: 50,
      type: "text",
      errors: {},
    },
    {
      key: "floor_number",
      label: "رقم الدور* ",
      isValid: false,
      validationFuncArray: [new RequiredValidator()],
      initialValue: "",
      maxLength: 30,
      type: "text",
      errors: {},
    },
    {
      key: "appartment_number",
      label: "رقم الشقة*",
      isValid: false,
      validationFuncArray: [new RequiredValidator()],
      initialValue: "",
      maxLength: 10,
      type: "text",
      errors: {},
    },
    {
      key: "customer_city",
      label: "المحافظة*",
      isValid: false,
      validationFuncArray: [new DropDownRequiredValidator()],
      initialValue: -1,
      type: "autocomplete-drop-down",
      errors: {},
      dropdown: {
        optionArray: cities,
        valueKey: "name",
        idKey: "id",
      },
    },
    {
      key: "customer_area",
      label: "المنطقة*",
      isValid: false,
      validationFuncArray: [new DropDownRequiredValidator()],
      initialValue: -1,
      type: "autocomplete-drop-down",
      errors: {},
      dropdown: {
        optionArray: areas,
        valueKey: "name",
        idKey: "id",
      },
    },
  ];
  const [errors, setErrors] = useState(
    FormHelper.initializeErrors(formFieldArray)
  );
  const [form, setForm] = useState(FormHelper.initializeForm(formFieldArray));

  function setCitiesState() {
    locationRepo.getCities().then((data) => {
      setCities(data.cities);
    });
  }
  function onFormChange(key: string, value) {
    mixPanelTrack(key);
    if (key === "customer_city") {
      setAreasState(value);
    }
  }
  function mixPanelTrack(key) {
    if (key === "google-maps") {
      mixpanel.track("OC-V3-location-change");
    }
  }
  function setAreasState(city_id) {
    setAreas([]);
    locationRepo.getAreas(city_id).then((data) => {
      setAreas(data.areas);
    });
  }
  function submitForm() {
    let isValid = FormHelper.validateForm(
      formFieldArray,
      form,
      errors,
      setErrors
    );
    if (isValid) {
      let createOrderPayload = FormHelper.getOrderCreationPayload(
        form,
        quantity
      );
      const chosenArea = areas.filter((area) => {
        return area.id === form["customer_area"];
      })[0];
      let orderData: OrderData = {
        orderRequest: createOrderPayload,
        shipping_fees: chosenArea.shipment_fees,
        total_fees_before_shipping: totalPrice,
      };
      dispatch(addOrder(orderData));
      navigate(`customer-bill`);
    }
  }
  function onQtyChange(qty: number, totalPrice: number) {
    setQuantity(qty);
    setTotalPrice(totalPrice);
  }

  return (
    <div>
      <TemplateTitle />
      <VariantData
        totalPrice={totalPrice}
        originalPrice={quantity * 300}></VariantData>
      <Form
        formFieldArray={formFieldArray}
        form={form}
        formSetter={setForm}
        errors={errors}
        marketPlace={store.getState().template.activeTemplate.marketplace.name}
        errorsSetter={setErrors}
        onChange={onFormChange}
        template="3"></Form>
      <VariantQuantityIncrement
        onQtyChange={onQtyChange}></VariantQuantityIncrement>
      <SubmitButton
        disable={false}
        buttonText="اكمال عملية الشراء"
        onSubmit={submitForm}
      />
    </div>
  );
};
