import React, { useLayoutEffect } from "react";
import mixpanel from "../../services/mixpanel";
import { store } from "../../redux/store";
import "./variant-quanitity-increment.scss";
interface Props {
  onQtyChange?: any;
}
export const VariantQuantityIncrement: React.FC<Props> = (props) => {
  let [price, getPriceFromState] = React.useState(0);
  let [totalPrice, getTotalPrice] = React.useState(0);
  let [quantity, setQuantity] = React.useState(1);
  const min = 1;
  const max = 10;

  useLayoutEffect(() => {
    getPriceFromState(store.getState().template.activeTemplate.selling_price);
    getTotalPrice(store.getState().template.activeTemplate.selling_price);
  }, []);

  const recalculateTotalPrice = (value) => {
    totalPrice = price * value;
    getTotalPrice(totalPrice);
  };

  const increment = () => {
    mixpanel.track("incrementing-quantity");
    if (quantity < max) {
      quantity++;
      setQuantity(quantity);
      recalculateTotalPrice(quantity);
      emitQtyChange();
    }
  };
  const emitQtyChange = () => {
    if (props?.onQtyChange) {
      props.onQtyChange(quantity, totalPrice);
    }
  };
  const decrement = () => {
    mixpanel.track("decrementing-quantity");
    if (quantity > min) {
      quantity--;
      setQuantity(quantity);
      recalculateTotalPrice(quantity);
      emitQtyChange();
    }
  };

  return (
    <div className="increment-container">
      <span className="quantity-picker">
        <button onClick={increment}>&#xff0b;</button>
        <input
          className="quantity-display"
          type="text"
          key={quantity}
          value={quantity}
          readOnly
        />
        <button onClick={decrement}>&ndash;</button>
      </span>
    </div>
  );
};
