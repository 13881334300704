import { CreateOrderRequest } from "../../services/core/models/order.model"

export const ADD_ORDER = "ADD_ORDER"
export interface OrderData{
    orderRequest:CreateOrderRequest,
    shipping_fees:number,
    total_fees_before_shipping:number,
}
export interface IAddOrderAction{
    type: typeof ADD_ORDER,
    orderData:OrderData
}

export type OrderAction = IAddOrderAction