import { store } from "../../redux/store";
import "./logo.scss";
export const LogoComponent = (props) => {
  const logoLink = store.getState().template.activeTemplate.logoLink;
  if (logoLink &&  typeof logoLink.src !== "undefined") {
    return (
      <div className="main-div">
        <img className="img-div" src={logoLink.src} alt="" />
      </div>
    );
  } else {
    return null;
  }
};

export default LogoComponent;
