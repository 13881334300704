import { ADD_ORDER, OrderAction } from "../actions/order-actions.interface";
import { IOrderState } from "../states/order.state";
export const orderIntitialState: IOrderState = {
    orderData: {
        orderRequest: {
            customer_name: "",
            customer_phone_number: "",
            customer_secondary_phone_number: "",
            customer_area: -1,
            customer_address_line: "",
            longitude: -1,
            latitude: -1,
            quantity: 0
        },
        shipping_fees: -1,
        total_fees_before_shipping: -1
    }
}

export const orderReducer = (prevState: IOrderState = orderIntitialState, action: OrderAction): IOrderState => {
    switch (action.type) {
        case ADD_ORDER:
            return { ...prevState, orderData: action.orderData }
        default:
            return prevState
    }
};