import { Component } from "react";
import { Loader, LoaderOptions } from "google-maps";
import "./google-maps.css";
import classNames from "classnames";

class GoogleMapComponent extends Component<any, any> {
  initialLat: number = 30.0243;
  initialLong: number = 31.222083;
  options: LoaderOptions = {};
  loader: Loader;
  showButton: boolean = true;
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.loader = new Loader(
      "AIzaSyBtsbu3pKbkD2IKkpCrZ7rwQHlHlMKBZD4",
      this.options
    );
    this.state = {
      selectedPosition: {
        lat: this.initialLat,
        lng: this.initialLong,
      },
      showButton: true,
      zoom: 10,
    };
  }

  async componentDidMount() {
    const google = await this.loader.load();
    new google.maps.Map(document.getElementById("map-canvas")!, {
      center: { lat: this.initialLat, lng: this.initialLong },
      zoom: 15,
    })
  }

  setMarker(map, location) {
    let newPosition = { lat: location.lat(), lng: location.lng() };
    this.props.onPositionChange(newPosition);
    console.log("location: " + location);
    map.setCenter(location);
    var marker = new google.maps.Marker({
      position: location,
      map: map,
      draggable: true,
      title: "You are here! Drag the marker to the exact location.",
    });
    google.maps.event.addListener(marker, "dragend", () => {
      this.props.onPositionChange(newPosition);
    });
  }

  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
  };
 async  onRequestLocationClick() {
  console.log("requesting location..")
  const google = await this.loader.load();
  //@ts-ignore
  const map = new google.maps.Map(document.getElementById("map-canvas"), {
    center: { lat: this.initialLat, lng: this.initialLong },
    zoom: 15,
  });
  navigator.geolocation.getCurrentPosition(
    (position) => {
      var location = new google.maps.LatLng(
        position.coords.latitude,
        position.coords.longitude
      );
      this.setMarker(map, location);
      this.setState({showButton: false});
    },
    () => {
      console.log("error");
    }
  );

  }

  render() {
    return (
      // Important! Always set the container height explicitly
      <div className="map-container">
        <div id="map-canvas"></div>
        <div
        className={classNames({
          "select-location": this.state.showButton,
          "none": !this.state.showButton})}>
          <button className="select-location-btn" onClick={()=>this.onRequestLocationClick()}>حدد موقعي</button>
        </div>
      </div>
    );
  }
}

export default GoogleMapComponent;
