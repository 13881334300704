import { CreateOrderRequest } from "../services/core/models/order.model";
import { convertToEnglishNumber } from "../validators/phone-regex";
import { IValidator } from "../validators/validator-interface";
import { FormFieldModel } from "./form-field.model";

export class FormHelper {
  static initializeErrors(formFieldArray: FormFieldModel[]) {
    let errors = {};
    for (let formField of formFieldArray) {
      errors[formField.key] = {};
    }
    return errors;
  }
  static initializeForm(formFieldArray: FormFieldModel[]) {
    let form = {};
    for (let formField of formFieldArray) {
      form[formField.key] = formField.initialValue;
    }
    return form;
  }
  static validateForm(
    formFieldArray: FormFieldModel[],
    formData: any,
    errors: any,
    errosSetter: any
  ) {
    let errorsData = {};
    let isFormValid = true;
    for (let formField of formFieldArray) {
      let key = formField.key;
      let value = formData[key];
      let formValidationResult = FormHelper.validateFormField(
        value,
        formField.validationFuncArray
      );
      isFormValid = isFormValid && formValidationResult[0];
      errorsData[key] = formValidationResult[1];
    }
    errosSetter(errorsData);
    return isFormValid;
  }
  static validateFormField(
    value: any,
    validatorArray: IValidator[]
  ): [boolean, any] {
    let isValid = true;
    let errors: any = {};
    for (let validator of validatorArray) {
      let validationResult = validator.validate(value);
      isValid = isValid && validationResult[0];
      errors = { ...errors, ...validationResult[1] };
    }
    return [isValid, errors];
  }
  static formatCustomerAddrressLine(
    street_name: string,
    building_number: string,
    floor_number: string,
    appartment_number: string,
    address_line: string = "",
    customer_city: string = ""
  ): string {
    let customer_address_line = ` عنوان العميل: ${address_line} ,`;
    customer_address_line += street_name ? ` اسم الشارع: ${street_name} ,` : ``;
    customer_address_line += building_number
      ? ` رقم المبنى: ${building_number} ,`
      : ``;
    customer_address_line += floor_number ? ` الدور: ${floor_number} ,` : ``;
    customer_address_line += appartment_number
      ? ` الشقة: ${appartment_number}`
      : ``;
    customer_address_line += customer_city ? ` -المدينه: ${customer_city}` : ``;
    return customer_address_line;
  }
  static getOrderCreationPayload(
    form: any,
    quantity: number = 1,
    shipping_fees: number = 0,
    prepaid_discount: number = 0,
    token_key: string = ''
  ): CreateOrderRequest {
    return {
      customer_name: form["customer_name"],
      customer_address_line: FormHelper.formatCustomerAddrressLine(
        form["street_name"] ? form["street_name"] : "",
        form["building_number"] ? form["building_number"] : "",
        form["floor_number"] ? form["floor_number"] : "",
        form["appartment_number"] ? form["appartment_number"] : "",
        form["address_line"] ? form["address_line"] : "",
        form["customer_city"] ? form["customer_city"] : ""
      ),
      customer_phone_number: convertToEnglishNumber(
        form["customer_phone_number"]
      ),
      customer_secondary_phone_number: convertToEnglishNumber(
        form["customer_secondary_phone_number"]
      ),
      customer_area: form["customer_area"],
      longitude: form["google-maps"]?.lng,
      latitude: form["google-maps"]?.lat,
      quantity: quantity,
      shipping_fees: shipping_fees,
      prepaid_discount: prepaid_discount,
      payment_method: form["payment_method"],
      token_key: token_key,
    };
  }

  static validateFormWithoutPayment(
    formFieldArray: FormFieldModel[],
    formData: any,
    errors: any,
    errosSetter: any
  ) {
    let errorsData = {};
    let isFormValid = true;
    for (let formField of formFieldArray) {
      let key = formField.key;
      let value = formData[key];

      if (key === "payment_method") {
        // isFormValid = true;
        errorsData[key] = {};
      } else {
        let formValidationResult = FormHelper.validateFormField(
          value,
          formField.validationFuncArray
        );
        isFormValid = isFormValid && formValidationResult[0];
        errorsData[key] = formValidationResult[1];
      }
    }
    errosSetter(errorsData);
    return isFormValid;
  }
}
