import classNames from "classnames";
import React from "react";
import "./submit-button.css";
interface Props {
  buttonText: string;
  onSubmit: any;
  disable: boolean;
}
export const SubmitButton: React.FC<Props> = (props) => {
  return (
    <button
      className={classNames({
        "success-bg": !props.disable,
        "grey-bg": props.disable,
        "submit-btn": true,
      })}
      disabled={props.disable}
      onClick={props.onSubmit}
    >
      {props.buttonText}
    </button>
  );
};
