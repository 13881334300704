import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import thunk from "redux-thunk";
import { CLEAR_STATE } from "./actions/app-actions.interface";
import { authReducer } from "./reducers/auth.reducer";
import { orderReducer } from "./reducers/order.reducer";
import { templateReducer } from "./reducers/template.reducer";
import { uiReducer } from "./reducers/ui.reducer";
import * as Sentry from "@sentry/react";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const appReducer = combineReducers({
  auth: authReducer,
  template: templateReducer,
  ui: uiReducer,
  order: orderReducer,
})

const rootReducer = (state, action) => {
  if (action.type === CLEAR_STATE) {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}


export const store = createStore(rootReducer, {}, compose(applyMiddleware(thunk), sentryReduxEnhancer));

export type RootState = ReturnType<typeof rootReducer>;

