import { ADD_ORDER, IAddOrderAction, OrderData } from "./order-actions.interface";

export function addOrder(orderData: OrderData): any {
    return dispatch => {
        let action: IAddOrderAction = {
            type: ADD_ORDER,
            orderData: orderData
        }
        dispatch(action);
    };
}