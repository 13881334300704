import jwt_decode from "jwt-decode";
import { INITIALIZE_TEMPLATE, ITemplateInterface, TemplateAction } from "./template-actions.interface";



  export function initializaTemplateAction(token: string):any {
    return dispatch => {
      let payload: ITemplateInterface = jwt_decode(token)
      let action:TemplateAction ={
        type:INITIALIZE_TEMPLATE,
        templateData:payload
      }
      dispatch(action);
    }; 
    
  }




