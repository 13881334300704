import { AuthAction } from "../actions/auth-actions.interface";
import { IAuthState } from "../states/auth.state";

const intitialState: IAuthState = {
  token: null,

};
export const AUTH_USER = 'AUTH USER';

export const authReducer = (prevState = intitialState, action: AuthAction) => {
  return {...prevState,token:action.token}
 
};
