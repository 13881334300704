import { Snackbar, Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { closeFailureSnackBar } from "../../redux/actions/ui-actions";
import { RootState } from "../../redux/store";

export const FailureSnackBar: React.FC = () => {
  let failureSnackBarData = useSelector(
    (state: RootState) => state.ui.failureSnackBar
  );
  const dispatch = useDispatch();
  function handleClose() {
    dispatch(closeFailureSnackBar());
  }
  return (
    <div>
      <Snackbar
        open={failureSnackBarData.failureSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {failureSnackBarData.failureSnackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};
