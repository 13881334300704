import { IValidator } from "./validator-interface"

export class RequiredValidator implements IValidator {

    public validate(value: any): [boolean, any] {
        try{
            let valueString = String(value)
            valueString = valueString.trim()
            if (valueString){
                return [true,{}]
            }
            return [false, { "required": true }]
        }
        catch(e){
            return [false, { "required": true }]
        }

        
    }

}

