import React, { useEffect, useState } from "react";
import { TemplateTitle } from "../../components/template-title";
import { FormFieldModel } from "../../helpers/form-field.model";
import { LocationRepository } from "../../services/data/web/repositories/location.repository";
import { RequiredValidator } from "../../validators/required-validator";
import "./first-template.scss";
import { Area, City } from "../../services/core/models/location.model";
import { OrderRepository } from "../../services/data/web/repositories/order.repository";
import mixpanel from "../../services/mixpanel";
import { useNavigate } from "react-router-dom";
import { PhoneNumberValidator } from "../../validators/phone-number-validator";
import { Form } from "../../components/form";
import { FormHelper } from "../../helpers/form-helper";
import { SubmitButton } from "../../components/submit-button";
import { useDispatch } from "react-redux";
import { openFailureSnackBar } from "../../redux/actions/ui-actions";
import { DropDownRequiredValidator } from "../../validators/dropdown-required-validator";
import { store } from "../../redux/store";

interface Props {
  order_code?: string;
}
export const FirstTemplate: React.FC<Props> = (props) => {
  const [cities, setCities] = React.useState<City[]>([]);
  const [areas, setAreas] = React.useState<Area[]>([]);
  const [loading, setLoading] = React.useState(false);
  const locationRepo = LocationRepository.getInstance();
  const orderRepository = OrderRepository.getInstance();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    mixpanel.time_event("OC-V1-page-entry");
    mixpanel.track("OC-V1-page-entry");
    // window.addEventListener("scroll", handleOnScroll);
    setCitiesState(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleOnScroll = () => {
  //   mixpanel.track("OC-V1-scroll");
  // };

  let formFieldArray: FormFieldModel[] = [
    {
      key: "customer_name",
      label: "الأسم*",
      isValid: false,
      validationFuncArray: [new RequiredValidator()],
      type: "text",
      maxLength: 100,
      initialValue: "",
      errors: {},
    },
    {
      key: "customer_phone_number",
      label: "رقم الموبايل 1 *",
      isValid: false,
      validationFuncArray: [
        new RequiredValidator(),
        new PhoneNumberValidator(
          store.getState().template.activeTemplate.marketplace.name
        ),
      ],
      type: "text",
      initialValue: "",
      errors: {},
    },
    {
      key: "customer_secondary_phone_number",
      label: "رقم الموبايل2",
      isValid: true,
      validationFuncArray: [
        new PhoneNumberValidator(
          store.getState().template.activeTemplate.marketplace.name
        ),
      ],
      initialValue: "",
      type: "text",
      errors: {},
    },
    {
      key: "google-maps",
      label: "قم باختيار موقعك من الخريطة ( ينصح به)",
      isValid: true,
      validationFuncArray: [],
      initialValue: {},
      type: "google-maps",
      errors: {},
    },

    {
      key: "street_name",
      label: "اسم ورقم الشارع* ",
      isValid: false,
      validationFuncArray: [new RequiredValidator()],
      initialValue: "",
      maxLength: 100,
      type: "text",
      errors: {},
    },
    {
      key: "building_number",
      label: "رقم المبني*",
      isValid: false,
      validationFuncArray: [new RequiredValidator()],
      initialValue: "",
      maxLength: 50,
      type: "text",
      errors: {},
    },
    {
      key: "floor_number",
      label: "رقم الدور* ",
      isValid: false,
      validationFuncArray: [new RequiredValidator()],
      initialValue: "",
      maxLength: 30,
      type: "text",
      errors: {},
    },
    {
      key: "appartment_number",
      label: "رقم الشقة*",
      isValid: false,
      validationFuncArray: [new RequiredValidator()],
      initialValue: "",
      maxLength: 10,
      type: "text",
      errors: {},
    },
    {
      key: "customer_city",
      label: "المحافظة*",
      isValid: false,
      validationFuncArray: [new DropDownRequiredValidator()],
      initialValue: -1,
      type: "autocomplete-drop-down",
      errors: {},
      dropdown: {
        optionArray: cities,
        valueKey: "name",
        idKey: "id",
      },
    },
    {
      key: "customer_area",
      label: "المنطقة*",
      isValid: false,
      validationFuncArray: [new DropDownRequiredValidator()],
      initialValue: -1,
      type: "autocomplete-drop-down",
      errors: {},
      dropdown: {
        optionArray: areas,
        valueKey: "name",
        idKey: "id",
      },
    },
  ];
  const [errors, setErrors] = useState(
    FormHelper.initializeErrors(formFieldArray)
  );
  const [form, setForm] = useState(FormHelper.initializeForm(formFieldArray));

  function setCitiesState() {
    locationRepo.getCities().then((data) => {
      setCities(data.cities);
    });
  }
  function setAreasState(city_id) {
    setAreas([]);
    locationRepo.getAreas(city_id).then((data) => {
      setAreas(data.areas);
    });
  }

  function submitForm() {
    mixpanel.time_event("OC-V1-submit-form");
    let isValid = FormHelper.validateForm(
      formFieldArray,
      form,
      errors,
      setErrors
    );
    if (isValid) {
      mixpanel.track("OC-V1-submit-form");
      let createOrderRequest = FormHelper.getOrderCreationPayload(form);
      setLoading(true);
      orderRepository
        .createOrder(createOrderRequest)
        .then((data) => {
          setLoading(false);
          navigate("/success");
        })
        .catch((error) => {
          setLoading(false);
          dispatch(openFailureSnackBar(error?.message));
        });
    }
  }

  function onFormChange(key: string, value) {
    if (key === "customer_city") {
      setAreasState(value);
    }
  }

  return (
    <section>
      <TemplateTitle />
      <Form
        formFieldArray={formFieldArray}
        form={form}
        formSetter={setForm}
        errors={errors}
        marketPlace={store.getState().template.activeTemplate.marketplace.name}
        errorsSetter={setErrors}
        onChange={onFormChange}
        template="1"></Form>
      <SubmitButton
        disable={loading}
        buttonText="اجراء عملية الشراء"
        onSubmit={submitForm}
      />
    </section>
  );
};
