export function getTokenKeyFromQueryParams(): string {
  const token_key =
    new URLSearchParams(window.location.search).get("token_key") || "";
  return token_key;
}

export function getCodeFromQueryParams(): string {
  const code = new URLSearchParams(window.location.search).get("code") || "";
  return code;
}

export function getOrderCodeFromQueryParams(): string {
  const order_code =
    new URLSearchParams(window.location.search).get("order_code") || "";
  return order_code;
}

export function getPaymentIDFromQueryParams(): string {
  const payment_id =
    new URLSearchParams(window.location.search).get("payment_id") || "";
  return payment_id;
}

export function getFailureMessageFromQueryParams(): string {
  const failure_message =
    new URLSearchParams(window.location.search).get("failure_message") || "";
  return failure_message;
}
