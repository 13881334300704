import {
  AvailablePaymentResponse,
  CreateOrderRequest,
  CreateOrderResponse,
  PaymentCheckoutRequest,
  PaymentCheckoutResponse,
  SkuDiscountRequest,
  SkuDiscountResponse,
  VerifyPaymentOrderRequest,
  VerifyPaymentOrderResponse,
} from "../../../core/models/order.model";
import { IOrderRepository } from "../../../core/repositories/order-repository.interface";
import { WebClient } from "../web-client";

export class OrderRepository extends WebClient implements IOrderRepository {
  private static _instance: OrderRepository;
  private constructor() {
    super();
  }

  public static getInstance() {
    if (!this._instance) {
      this._instance = new OrderRepository();
    }
    return this._instance;
  }

  async createOrder(
    createOrderRequest: CreateOrderRequest
  ): Promise<CreateOrderResponse> {
    const response = this.instance.post(
      "app/order-catcher",
      createOrderRequest
    );
    return Promise.resolve(response);
  }

  async getAvailablePayment(
    createOrderRequest: CreateOrderRequest
  ): Promise<AvailablePaymentResponse> {
    const response = this.instance.post(
      "app/get-available-payment",
      createOrderRequest
    );
    return Promise.resolve(response);
  }

  async paymentCheckout(
    paymentCheckoutRequest: PaymentCheckoutRequest
  ): Promise<PaymentCheckoutResponse> {
    const response = this.instance.post(
      "app/payment-checkout",
      paymentCheckoutRequest
    );
    return Promise.resolve(response);
  }

  async verifyOrderPayment(
    verifyPaymentOrderRequest: VerifyPaymentOrderRequest
  ): Promise<VerifyPaymentOrderResponse> {
    const response = this.instance.patch(
      "app/verify-payment",
      verifyPaymentOrderRequest
    );
    return Promise.resolve(response);
  }

  async getProductDiscount(
    skuDiscountRequest: SkuDiscountRequest
  ): Promise<SkuDiscountResponse> {
    const response = this.instance.patch("app/sku-info", skuDiscountRequest);
    return Promise.resolve(response);
  }
}
