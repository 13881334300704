import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "../../components/form";
import { SubmitButton } from "../../components/submit-button";
import { VariantData } from "../../components/variant-data";
import { VariantQuantityIncrement } from "../../components/variant-quantity-increment";
import { VariantPrice } from "../../components/variant-price";
import { FormFieldModel } from "../../helpers/form-field.model";
import { FormHelper } from "../../helpers/form-helper";
import {
  phoneNumberPlaceholder,
  PhoneNumberValidator,
} from "../../validators/phone-number-validator";
import { RequiredValidator } from "../../validators/required-validator";
import mixpanel from "../../services/mixpanel";
import { useDispatch } from "react-redux";
import { store } from "../../redux/store";
import "./forth-template.scss";
import { OrderRepository } from "../../services/data/web/repositories/order.repository";
import { PaymentMethodValidator } from "../../validators/payment-method-validator";
import {
  SkuDiscountRequest,
  VerifyPaymentOrderRequest,
} from "../../services/core/models/order.model";
import { CircularProgress } from "@mui/material";
import { FailureDialog } from "../../components/failure-dialog";
import { openFailureSnackBar } from "../../redux/actions/ui-actions";
import LogoComponent from "../../components/logo/LogoComponent";
import inActive from "../../assets/logos/inActive.svg";

interface Props {
  order_code?: string;
  payment_id?: string;
  failure_message?: string;
}
export const ForthTemplate: React.FC<Props> = (props) => {
  const orderRepository = OrderRepository.getInstance();
  const [loading, setLoading] = React.useState(false);
  const [quantity, setQuantity] = React.useState(1);
  const [prepaidDiscountQuantity, setPrepaidDiscountQuantity] =
    React.useState(0);
  const [isWaitingPaymentLoading, setIsWaitingPaymentLoading] =
    React.useState(false);
  const [totalPrice, setTotalPrice] = React.useState(
    store.getState().template.activeTemplate.selling_price
  );
  const priceBeforeOffer = store.getState().template.activeTemplate
    .price_before_offer
    ? store.getState().template.activeTemplate.price_before_offer
    : 300;
  const marketplace_id = store.getState().template.activeTemplate.marketplace.id
    ? store.getState().template.activeTemplate.marketplace.id
    : 1;
  const shipping_price = store.getState().template.activeTemplate.shipping_price
    ? store.getState().template.activeTemplate.shipping_price
    : 30;
    let is_active: boolean = true;
    if (store.getState().template.activeTemplate.is_active !== undefined)
      is_active = store.getState().template.activeTemplate.is_active
  const token_key = store.getState().template.activeTemplate.token_key
    ? store.getState().template.activeTemplate.token_key
    : '';
  const formRef = useRef<any>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const title = "سجل  بياناتك لتأكيد الطلب";
  const title = "يمكنك الاستبدال او الاسترجاع خلال اسبوعين من استلام طلبك";
  const windowProtocol = window.location.protocol;
  const windowHost = window.location.host;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const tokenKey = urlSearchParams.get("token_key");
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [failureMessage, setFailureMessage] = React.useState("");

  const getPrepaidDiscount = useCallback(() => {
    setLoading(true);
    setIsWaitingPaymentLoading(true);
    const skuDiscountRequest: SkuDiscountRequest = {
      sku_code: store.getState().template.activeTemplate.variant_sku_code,
    };
    orderRepository
      .getProductDiscount(skuDiscountRequest)
      .then((payload) => {
        setPrepaidDiscountQuantity(payload.sku_info_response.sku_discount);

        setTimeout(() => {
          formRef.current.getAvailablePayment();
          setLoading(false);
          setIsWaitingPaymentLoading(false);
        }, 500);
      })
      .catch((error) => {
        dispatch(openFailureSnackBar(error?.message));
        formRef.current.getAvailablePayment();
        setLoading(false);
        setIsWaitingPaymentLoading(false);
      });
  }, [formRef, dispatch, orderRepository]);

  useEffect(() => {
    mixpanel.time_event("OC-V4-page-entry");
    mixpanel.track("OC-V4-page-entry");
    // window.addEventListener("scroll", handleOnScroll);
    getPrepaidDiscount();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if (props.order_code) {
      setIsWaitingPaymentLoading(true);
      let verifyPaymentOrderRequest: VerifyPaymentOrderRequest = {
        order_code: props?.order_code,
        payment_reference: props?.payment_id,
      };
      orderRepository
        .verifyOrderPayment(verifyPaymentOrderRequest)
        .then((payload) => {
          setIsWaitingPaymentLoading(false);
          if (payload.payment_response.status === "rejected") {
            if (props?.failure_message) {
              setDialogOpen(true);
              setFailureMessage(props.failure_message);
            } else {
              setDialogOpen(true);
              setFailureMessage(
                "عملية الدفع لم تكتمل بنجاح يرجي محاولة التسجيل مرة أخري"
              );
            }
          } else {
            navigate("/success");
          }
        })
        .catch((error) => {
          setIsWaitingPaymentLoading(false);
          setDialogOpen(true);
          setFailureMessage(error?.response?.data?.message);
        });
    }
  }, [
    getPrepaidDiscount,
    dispatch,
    navigate,
    orderRepository,
    props.order_code,
    props.payment_id,
    props.failure_message,
  ]);

  // const handleOnScroll = () => {
  //   mixpanel.track("OC-V4-scroll");
  // };

  let formFieldArray: FormFieldModel[] = [
    {
      key: "google-maps",
      label: "قم باختيار موقعك من الخريطة ( ينصح به)",
      isValid: true,
      validationFuncArray: [],
      initialValue: {},
      type: "google-maps",
      errors: {},
    },
    {
      key: "customer_name",
      label: "الأسم*",
      isValid: false,
      validationFuncArray: [new RequiredValidator()],
      type: "text",
      maxLength: 100,
      initialValue: "",
      placeholder: "",
      errors: {},
    },
    {
      key: "customer_phone_number",
      label: "رقم الموبايل  *",
      isValid: false,
      validationFuncArray: [
        new RequiredValidator(),
        new PhoneNumberValidator(
          store.getState().template.activeTemplate.marketplace.name
        ),
      ],
      type: "text",
      initialValue: "",
      placeholder: phoneNumberPlaceholder(
        store.getState().template.activeTemplate.marketplace.name
      ),
      errors: {},
    },

    {
      key: "address_line",
      label: "العنوان  *",
      isValid: false,
      validationFuncArray: [new RequiredValidator()],
      initialValue: "",
      maxLength: 250,
      type: "text",
      placeholder: "",
      errors: {},
    },
    {
      key: "customer_city",
      label: "المدينة  *",
      isValid: false,
      validationFuncArray: [new RequiredValidator()],
      initialValue: "",
      maxLength: 250,
      type: "text",
      placeholder: "",
      errors: {},
    },
  ];
  if (
    store.getState().template.activeTemplate.marketplace.name === "Saudi Arabia"
  ) {
    formFieldArray.push({
      key: "payment_method",
      label: "",
      isValid: false,
      validationFuncArray: [
        new RequiredValidator(),
        new PaymentMethodValidator(),
      ],
      initialValue: "cod",
      maxLength: 250,
      type: "radio",
      placeholder: "",
      errors: {},
      specialType: "payment",
    });
  }

  const [errors, setErrors] = useState(
    FormHelper.initializeErrors(formFieldArray)
  );
  const [form, setForm] = useState(FormHelper.initializeForm(formFieldArray));

  function onFormChange(key: string, value) {
    mixPanelTrack(key);
  }

  function mixPanelTrack(key) {
    if (key === "customer_name") {
      mixpanel.track("OC-V4-customer_name");
    } else if (key === "customer_phone_number") {
      mixpanel.track("OC-V4-customer_phone_number");
    } else if (key === "address_line") {
      mixpanel.track("OC-V4-address_line");
    } else if (key === "customer_city") {
      mixpanel.track("OC-V4-customer_city");
    } else if (key === "customer_city") {
      mixpanel.track("OC-V4-customer_city");
    }
  }

  const handleClose = () => {
    setDialogOpen(false);
    setFailureMessage("");
  };

  function submitForm() {
    console.log("submitForm");
    let isValid = FormHelper.validateForm(
      formFieldArray,
      form,
      errors,
      setErrors
    );
    if (isValid) {
      setLoading(true);
      setIsWaitingPaymentLoading(true);

      mixpanel.track("OC-V4-Buy_Now_after_completion_of_fields");
      if (+marketplace_id === 1) {
        form["customer_area"] = process.env.REACT_APP_DEFAULT_AREA_ID_EG;
      } else if (+marketplace_id === 2) {
        form["customer_area"] = process.env.REACT_APP_DEFAULT_AREA_ID_SA;
      } else if (+marketplace_id === 4) {
        form["customer_area"] = process.env.REACT_APP_DEFAULT_AREA_ID_UAE;
      }

      let createOrderPayload = FormHelper.getOrderCreationPayload(
        form,
        quantity,
        shipping_price,
        prepaidDiscountQuantity,
        token_key
      );
      orderRepository
        .createOrder(createOrderPayload)
        .then((payload) => {
          if (
            createOrderPayload.payment_method &&
            createOrderPayload.payment_method !== "cod"
          ) {
            mixpanel.track(
              `OC - selected payment method ${createOrderPayload.payment_method} for order ${payload.seller_response["order_code"]}`
            );

            let sucess_browser_redirect_url = `${windowProtocol}//${windowHost}/?token_key=${tokenKey}&order_code=${payload.seller_response["order_code"]}`;
            let failure_browser_redirect_url = `${windowProtocol}//${windowHost}/?token_key=${tokenKey}&order_code=${payload.seller_response["order_code"]}&failure_message=نأسف، تابي غير قادرة على الموافقة على هذه العملية. الرجاء استخدام طريقة دفع أخرى.`;
            let cancel_browser_redirect_url = `${windowProtocol}//${windowHost}/?token_key=${tokenKey}&order_code=${payload.seller_response["order_code"]}&failure_message=لقد ألغيت الدفعة. فضلاً حاول مجددًا أو اختر طريقة دفع أخري`;
            orderRepository
              .paymentCheckout({
                ...createOrderPayload,
                success_url: sucess_browser_redirect_url,
                failure_url: failure_browser_redirect_url,
                cancel_url: cancel_browser_redirect_url,
                order_code: payload.seller_response["order_code"],
                prepaid_discount: prepaidDiscountQuantity,
              })
              .then((payment_payload) => {
                setIsWaitingPaymentLoading(false);
                window.location.href =
                  payment_payload.payment_response.redirect_url;
              })
              .catch((error) => {
                setLoading(false);
                setIsWaitingPaymentLoading(false);

                if (props.failure_message) {
                  dispatch(openFailureSnackBar(props.failure_message));
                } else {
                  dispatch(openFailureSnackBar(error?.response?.data?.message));
                }
              });
          } else {
            setIsWaitingPaymentLoading(false);
            navigate("/success");
          }
        })
        .catch((error) => {
          setLoading(false);
          setIsWaitingPaymentLoading(false);
          console.log(error)
          dispatch(openFailureSnackBar(error?.response?.data?.errorMessage));
        });
    } else {
      mixpanel.track("OC-V4-Buy_Now_before_completion_of_fields");
      let titleElement = document.getElementById("title");
      titleElement?.scrollIntoView();
    }
  }

  function onQtyChange(qty: number, totalPrice: number) {
    setIsWaitingPaymentLoading(true);
    setQuantity(qty);
    setTotalPrice(totalPrice);
    if (
      store.getState().template.activeTemplate.marketplace.name ===
      "Saudi Arabia"
    ) {
      setTimeout(() => {
        formRef.current.getAvailablePayment();
        setIsWaitingPaymentLoading(false);
      }, 500);
    } else {
      setIsWaitingPaymentLoading(false);
    }
  }
  return (
    <div>
      {is_active && (
    <div className="container">
      {isWaitingPaymentLoading && (
        <div className="loading--overlay">
          <div className="spinner">
            <CircularProgress />
            <div className="waiting-message">
              برجاء الانتظار جاري التحقق من البيانات
            </div>
          </div>
        </div>
      )}
      
      <div className="header">
        عرض خاص: التوصيل بالمجان، و الدفع عند الاستلام
      </div>
      <LogoComponent></LogoComponent>
      <div className="screen-content">
        <VariantData
          totalPrice={totalPrice}
          originalPrice={quantity * priceBeforeOffer}></VariantData>
        <VariantPrice
          totalPrice={totalPrice}
          originalPrice={quantity * priceBeforeOffer}></VariantPrice>
        <Form
          title={title}
          formFieldArray={formFieldArray}
          marketPlace={
            store.getState().template.activeTemplate.marketplace.name
          }
          form={form}
          formSetter={setForm}
          errors={errors}
          errorsSetter={setErrors}
          onChange={onFormChange}
          quantity={quantity}
          template="4"
          ref={formRef}
          prepaidDiscountQuantity={prepaidDiscountQuantity}></Form>
      </div>
      <div className="spacerElement"></div>
      <div className="screen-footer">
        <VariantQuantityIncrement
          onQtyChange={onQtyChange}></VariantQuantityIncrement>
        <SubmitButton
          disable={loading}
          buttonText="اضغط هنا للطلب"
          onSubmit={submitForm}
        />
      </div>
       

      <FailureDialog
        failureMessage={failureMessage}
        open={dialogOpen}
        onClose={handleClose}
      />
   
    </div>
      )}
      {!is_active && (
        <div className="empty-div">
          <img className = "in-active-image" alt="inActive" src={inActive} />
            <h2 className="in-active">الصفحة غير موجوده حاليا</h2>
        </div>

        
      )}
    </div>
      
  );
};
