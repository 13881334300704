import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import plus_logo from "../../assets/logos/plus.svg";
import "./customer-bill.scss";
import { SubmitButton } from "../submit-button";
import { OrderRepository } from "../../services/data/web/repositories/order.repository";
import { useNavigate } from "react-router-dom";
import { openFailureSnackBar } from "../../redux/actions/ui-actions";
import mixpanel from "../../services/mixpanel";
import { orderIntitialState } from "../../redux/reducers/order.reducer";

export const CustomerBill: React.FC = () => {
  const orderData = useSelector((state: RootState) => state.order.orderData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderRepository = OrderRepository.getInstance();
  const templateData = useSelector(
    (state: RootState) => state.template.activeTemplate
  );
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (orderData === orderIntitialState.orderData) {
      navigate(-1);
    }
  }, [orderData, navigate]);
  function submitForm() {
    mixpanel.time_event("OC-V3-submit-form");
    mixpanel.track("OC-V3-submit-form");
    setLoading(true);
    orderRepository
      .createOrder(orderData.orderRequest)
      .then(() => {
        setLoading(false);
        navigate("/success");
      })
      .catch((error) => {
        setLoading(false);
        dispatch(openFailureSnackBar(error?.message));
      });
  }
  return (
    <div className="customer-bill-conatiner ">
      <h2 className="success" style={{ textAlign: "center" }}>
        فاتورة الشراء
      </h2>
      <div className="bill center">
        <h2 className="blue" style={{ textAlign: "center" }}>
          {templateData.product_name}
        </h2>
        <div>
          <h4 className="grey">تكلفة الأوردر بدون مصاريف الشحن</h4>
          <h4>
            {" "}
            {orderData.total_fees_before_shipping}{" "}
            {templateData.marketplace.currency}
          </h4>
          <div className="icon-styles">
            <img alt="plus_logo" style={{ width: "24px" }} src={plus_logo} />
          </div>
          <h4 className="grey">مصاريف الشحن</h4>
          <h4>
            {" "}
            {orderData.shipping_fees} {templateData.marketplace.currency}
          </h4>
          <div className="horizontal-line"></div>
          <h2 style={{ textAlign: "center" }}>
            تكلفة الأوردر شامل مصاريف الشحن
          </h2>
          <h2 className="success" style={{ textAlign: "center" }}>
            {orderData.shipping_fees + orderData.total_fees_before_shipping}{" "}
            {templateData.marketplace.currency}
          </h2>
        </div>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <SubmitButton
          disable={loading}
          buttonText="تكملة عملية الشراء"
          onSubmit={submitForm}
        />
      </div>
    </div>
  );
};
