import { CLOSE_FAILURE_SNACKBAR, ICloseFailureSnackBarAction, IOpenFailureSnackBarAction, OPEN_FAILURE_SNACKBAR } from "./ui-actions.interface";

export function openFailureSnackBar(message: string): any {
    return dispatch => {
        let action: IOpenFailureSnackBarAction = {
            type: OPEN_FAILURE_SNACKBAR,
            message: message
        }
        dispatch(action);
    };
}

export function closeFailureSnackBar(): any {
    return dispatch => {
        let action: ICloseFailureSnackBarAction = {
            type: CLOSE_FAILURE_SNACKBAR,
        }
        dispatch(action);
    };

}